<template>
  <div>
    <div class="form-group">
      <label>Please Enter reason to Cancel the Order</label>
      <b-form-textarea
          id="textarea"
          v-model="cancelReason"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
    </div>
    <button
    v-on:click="cancelOrder"
    style="float:right"
        class="btn btn-danger mt-1"
      >
        Cancel Order
      </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CancelOrder",
  data() {
    return {
        cancelReason: "",
    };
  },
  methods: {
    cancelOrder() {
        this.$emit("cancelReason" , this.cancelReason)
    }
  },
}
</script>

<style scoped>

</style>