<template>
    <div>
        <div class="form-group">
        <label>Amount </label>
        <b-form-input
            id="textarea"
            v-model="amount"
          
          ></b-form-input>
      </div>
      <div class="form-group">
        <label>Please Enter message </label>
        <b-form-textarea
            id="textarea"
            v-model="message"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
      </div>
      <button
      v-on:click="createLink"
      style="float:right"
          class="btn btn-danger mt-1"
        >
        Send Link
        </button>
    </div>
  </template>
  
  <script>
  export default {
    name: "CancelOrder",
    data() {
      return {
        message: "",
        amount:""
      };
    },
    methods: {
      createLink() {

      }
    },
  }
  </script>
  
  <style scoped>
  
  </style>