<template>
  <div>
    <p style="text-align: center">
      <span style="font-size: 23px"><b>{{ order.business.name }}</b></span>
      <br>
      {{ order.business.store_name }}
      <br>
      {{ order.business.address }}, {{ order.business.city }},
      <br>
      <span style="font-size: 11px">{{ format_date(order.created_at) }}</span>
    </p>
    <hr>

    <p style="text-align: left">
      <span style="font-size: 18px"><b>Customer Details</b></span>
      <br>
      <b>{{ order.customer.name }}</b>
      <br>
      Billing : {{ order.customer.billing.length > 0 ? order.customer.billing[0].address : '' }} {{ order.customer.billing.length > 0 ? order.customer.billing[0].city : '' }}
      <br>
      Delivery : {{ order.customer.delivery.length > 0 ? order.customer.delivery[0].address : '' }} {{ order.customer.delivery.length > 0 ? order.customer.delivery[0].city : '' }}
      <br>
      Email : {{ order.customer.email }}
    </p>
    <hr>

    <p style="text-align: left">
      <span style="font-size: 18px"><b>Order Details</b></span>
      <br>
      Order Number : {{ order.id }}
      <br>
      Order Time : {{ format_date(order.created_at) }} {{ format_time(order.created_at) }}
      <br>
      <span v-if="order.type === 'Collection'">Requested for : {{ format_date(order.collection_date) }}  {{ order.collection_time }}</span>
      <br v-if="order.type === 'Collection'">
      Order Type : {{ order.type }}
      <br>
      Payment Status : <b>{{ order.payment_method == 'card' ? 'CARD / PAID' : 'CASH / UNPAID' }}</b>
      <br>
      Note : {{ order.order_note }}
    </p>
    <hr>
    <div class="table-responsive">
      <table
          class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
            ">
        <thead>
          <tr class="text-left">
            <th style="min-width: 70%">
              <span class="text-dark-75">Product</span>
            </th>
            <th style="min-width: 15%">
              <span class="text-dark-75">Qty</span>
            </th>
            <th style="min-width: 15%">
              <span class="text-dark-75">Total</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in order.products">
            {{ item.products }}
            <td>
              <span class="font-weight-bold">{{ item.name }} - {{ item.spicyness }}</span>
              <br>
              <span v-for="t in JSON.parse(item.pivot.toppings)">{{ t.names }} , </span>
              <span v-for="t in JSON.parse(item.pivot.properties)[0]">{{ t.name }} , </span>
            </td>
            <td>
              <span class="font-weight-bold">{{ item.pivot.quantity }}</span>
            </td>
            <td>
              <span class="font-weight-bold">{{ parseFloat(item.pivot.amount).toFixed(2) }}</span>
            </td>
          </tr>
         </tbody>
      </table>
  </div>
  <br>
    <p style="text-align: right" :key="index" v-for="(total, index) in order.totals">
      <b>{{ total.key }} : </b>{{ total.total }}
      <br>
    </p>
    <p style="text-align: right">
      <b>Total : </b>{{ order.total }}
    </p>
  </div>
</template>

<script>
import { INVOICE } from "@/core/services/store/sales.module";
import moment from 'moment'
import { mapGetters } from "vuex";
export default {
  name: "Invoice",
  props: ["data"],
  data() {
    return {
      order : ''
    };
  },
  methods: {
    getOrderDetails() {
      this.$store.dispatch(INVOICE, this.data.order_id).then(res => {
        this.order = res
      });
    },
    format_date(value){
      if (value) {
        return moment(String(value)).format('Do MMM, YYYY')
      }
    },
    format_time(value){
      if (value) {
        return moment(String(value)).format('hh:mm A')
      }
    },
    get_toppings_total(toppings){
      let sum;
      sum = 0;
      let i;
      for (i = 0 ; i < toppings.length ; i++)
      {
        sum += toppings[i].topp_price
      }
      return sum;
    },
  },
  mounted() {
    this.getOrderDetails()
  }
}
</script>

<style scoped>

</style>